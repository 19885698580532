import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import PageBanner from "../components/pageBanner"
import PageSection from "../components/pageSection"
import ComponentFactory from "../ComponentFactory"

const NotFoundPage = ({ data }) => {
  // const { banner, sections } = data.strapiPageNotFound
  const { banner, sections } = data.CMS.pageNotFound
  const pageTags = data.CMS.pageNotFound.metadata.tags

  const sectionEls = sections.map(section => {
    const { components, customClass, id } = section
    return (
      <PageSection className={customClass} key={`section_${id}`}>
        {components.map(data => ComponentFactory.create(data))}
      </PageSection>
    )
  })

  return (
    <Layout>
      <SEO pageTags={pageTags} />
      <PageBanner {...banner} />
      {sectionEls}
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    CMS {
      pageNotFound {
        id
        banner {
          ...PageBanner
        }
        metadata {
          ...Metadata
        }
        sections {
          id
          customClass: classname
          components {
            ...Component
          }
        }
      }
    }
  }
`
